<template>
  <div class="d-flex-column main mw-md">
    <RegoCard
      v-for="(rego, index) in completed"
      :key="index"
      :rego="rego"
      :edit="() => editLocal(rego)"
      :del="() => del(rego)" />
      <div class="align-text-center">
        <el-button type="primary" class="full-width mw-400" @click="completeRegistration">Continue</el-button>
      </div>
      <div class="align-text-center">
        <el-button type="success" class="full-width mw-400" @click="anotherRego">Another Registration</el-button>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import RegoCard from '@/components/card/RegoCard.vue';

export default {
  name: 'RegoCart',
  components: {
    RegoCard,
  },
  computed: {
    ...mapGetters('registration', [
      'completed',
    ]),
  },
  mounted() {
    // Hook to redirect if removed registration last one standing
    if (!this.completed || this.completed.length === 0) this.$router.push({ name: 'chooseprofile' });
  },
  methods: {
    ...mapActions('registration', [
      'edit',
    ]),
    ...mapActions('views', ['notify']),
    ...mapMutations('registration', [
      'DELETE_COMPLETED',
    ]),
    del(rego) {
      this.DELETE_COMPLETED(rego);
      // Hook to redirect if removed registration last one standing
      if (!this.completed || this.completed.length === 0) this.$router.push({ name: 'chooseprofile' });
      else {
        this.notify({
          autoClose: true,
          msg: 'Registration removed!',
          type: 'warning',
        });
      }
    },
    async editLocal(rego) {
      // Add to Current + Remove from Completed
      await this.edit(rego);
      // Redirect
      this.$router.push({ name: 'participantdetails' });
    },
    anotherRego() {
      this.$router.push({ name: 'registeranother' });
    },
    completeRegistration() {
      // Check new members don't have multliple player-tackle or referee regos
      const uniqMemberIds = [...new Set(this.completed.map(r => r.id))];
      const multiplePlayerTackle = uniqMemberIds
        .reduce((acc, id) => (this.completed
          .filter(r => r.id === id && r.priceItem && r.priceItem.memberType === 'player').length > 1 || acc), false);
      if (multiplePlayerTackle) {
        this.notify({
          msg: `Unfortunately you cannot register a new member to two different clubs as a Player (Tackle). 
            Please remove one of the registrations to continue.`,
          type: 'warning',
        });
        return;
      }
      const multipleReferees = uniqMemberIds
        .reduce((acc, id) => (this.completed
          .filter(r => r.id === id && r.priceItem.memberType === 'referee').length > 1 || acc), false);
      if (multipleReferees) {
        this.notify({
          msg: `Unfortunately you cannot register a new member to two different organisations as a Referee. 
            Please remove one of the registrations to continue`,
          type: 'warning',
        });
        return;
      }

      this.$router.push({ name: 'payment' });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .button-transparent {
  border: 2px solid grey !important;
}
</style>
