<template>
  <div class="align-center align-text-center">
    <img :src="rego.img" alt="Profile Picture" class="profile-popping" v-if="rego.img" />
    <el-card
      class="d-flex-column align-text-center profile full-width"
      :class="rego.img ? 'with-img' : null"
    >
      <h2 class="capitalize names mt-1 no-margin">
        {{
          rego.profile && rego.profile.participantForm ? rego.profile.participantForm.firstName : ""
        }}
        {{
          rego.profile && rego.profile.participantForm ? rego.profile.participantForm.lastName : ""
        }}
      </h2>
      <p class="capitalize names mt5">{{ mappedRegoType }}</p>
      <p class="capitalize">{{ rego.entity.name }}</p>

      <el-button
        id="editRegoButton"
        @click="edit"
        class="full-width mw-311 button-light button-thin"
      >
        <span class="thick">Edit Registration</span>
      </el-button>
      <div class="mt-1">
        <a href="#" @click.prevent="del" class="small-font">Remove</a>
      </div>
    </el-card>
  </div>
</template>

<script>
import { memberTypes } from "@/utils/constants";

export default {
  name: "RegoCard",
  props: {
    rego: Object,
    edit: Function,
    del: Function
  },
  computed: {
    mappedRegoType() {
      const found = memberTypes.find(t => t.type === this.rego.type);
      return found ? found.name : "";
    }
  }
};
</script>

<style scoped lang="scss">
.capitalize {
  text-transform: capitalize;
}
.bold {
  font-family: $fontBold;
}
.names {
  font-family: $fontMedium, Arial;
}

.mt5 {
  margin-top: 5px;
}

.profile {
  .profile-popping {
    align-self: center;
    width: 180px;
    height: 180px;
  }
}

.el-card__body {
  width: 100% !important;
}

.thick {
  letter-spacing: 0.1em;
  color: #616161;
}

.small-font {
  font-size: 0.9rem;
}
</style>
